body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1c1d1e;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/**********  NavBar   **********/

.nav-bar {
  content-visibility: hidden;
}

.nav-bar-visible {
  position: fixed;
  top: 2%;
  left: 7%;
  width: 90%;
  padding: 7px;
}

.nav-bar-visible .nav-visible {
  font-size: 0.8em;
  margin-right: 3%;
  color: white;
  text-shadow: 0 0 3px white;
}

.nav-bar .nav-not-visible {
  content-visibility: hidden;
}

@media only screen and (max-width: 1200px) {
  .nav-bar-visible {
    display: none;
  }

  .nav-bar {
    display: none;
  }

  .nav-visible {
    display: none;
  }
}

/**********  HOME PAGE   **********/

#ak-logo-link {
  position: fixed;
  top: 2%;
  right: 93%;
}

#ak-logo {
  width: 60%;
}

.home {
  top: 0;
}

.home #home-my-name {
  color: #cfb2db;
  font-size: 7vw;
  font-family: Playfair Display, serif;
  font-weight: 900;
  margin-top: 4.8%;
  margin-bottom: 0;
  position: relative;
  display: inline-block;
}

.home #home-my-pic {
  width: 40%;
  position: absolute;
  top: 24%;
  right: 30%;
}

.home #home-brush {
  width: 12%;
  position: absolute;
  top: 66%;
  right: 63%;
}

.home-nav {
  margin-top: 6%;
  margin-left: 75%;
  display: block;
  text-align: left;
}

.home-nav .home-nav-link {
  color: white;
  padding: 0;
  text-shadow: 0 0 3px white;
}

.home #home-job-title {
  color: #cfb2db;
  font-family: Playfair Display, serif;
  font-weight: 700;
  font-size: 2vw;
  margin-top: 12%;
}

#home-links {
  margin-bottom: 4%;
}

#home-links .logo-link {
  width: 3%;
  margin: 0 9px 21px;
}

/*      HOME PAGE - Mobile responsive     */

@media only screen and (max-width: 1200px) {
  #ak-logo-link {
    position: fixed;
    top: 0.5%;
    right: 90%;
  }

  #ak-logo {
    width: 80%;
  }

  .home #home-my-name {
    font-size: 7vw;
    margin-top: 4.8%;
  }

  .home #home-my-pic {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    position: initial;
  }

  .home #home-brush {
    visibility: hidden;
  }

  .home-nav {
    margin: 5% 0 0 0;
    display: inline-block;
  }

  .home-nav .home-nav-link {
    font-size: 1.8vw;
    text-shadow: 0 0 3px rgb(214, 194, 194);
  }

  .home #home-job-title {
    font-size: 2.9vw;
    margin: 3% 0 0 0;
  }

  #home-links {
    margin-bottom: 5%;
    margin-top: 1%;
  }

  #home-links .logo-link {
    width: 3%;
    margin: 0 5px;
  }
}

/**********  About Me Page   **********/

.about-me {
  background-color: #222626;
}

.carousel {
  width: 35%;
  height: auto;
  margin: 12% 4% 0%;
  display: inline-block;
  /* box-shadow: 0 4px 8px 0 rgba(169, 164, 164, 0.455),
    0 6px 20px 0 rgba(202, 199, 199, 0.431); */
}

.carousel-img {
  width: 100%;
  height: 100%;
}

.about-me #carousel-brush {
  width: 14%;
  position: absolute;
  top: 150%;
  right: 5.5%;
}

.bio #about-me-title {
  margin: 0 0 10% 0;
  color: #cfb2db;
  font-size: 6vw;
  font-family: Playfair Display, serif;
  width: 70%;
  text-align: left;
}

.bio #about-me-title p {
  margin: 0;
  padding: 0;
  line-height: 60px;
}

.bio {
  font-size: 1.1vw;
  display: inline-block;
  width: 39%;
  margin: 6% 3% 6%;
  vertical-align: top;
  color: #e8e4ea;
}

#about-me-icons p {
  color: #e8e4ea;
  font-size: 12px;
  margin: 0;
}

#about-me-icons .about-me-hobby-icon {
  align-content: center;
  display: inline-block;
  margin: 0% 3%;
  width: 6%;
  vertical-align: top;
}

#about-me-icons .about-me-logo {
  width: 40px;
  margin: 0;
}

.about-me a {
  color: #495251;
}

/*      About Me - Mobile responsive     */

@media only screen and (max-width: 1200px) {
  .about-me #carousel-brush {
    visibility: hidden;
  }

  .bio {
    font-size: 2vw;
    width: 70%;
    vertical-align: top;
  }

  .bio #about-me-title {
    margin: 0 0 10% 0;
    font-size: 8vw;
  }

  .bio #about-me-title p {
    margin: 0;
    padding: 0;
    line-height: 75%;
  }

  .carousel {
    width: 60%;
    height: auto;
    margin: 0 0 5% 0;
  }

  #about-me-icons p {
    font-size: 2vw;
    text-align: center;
    margin: 0;
  }

  #about-me-icons .about-me-hobby-icon {
    display: inline-block;
    width: 6%;
    vertical-align: top;
    margin: 0 2.6% 0 2.6%;
  }

  #about-me-icons .about-me-logo {
    width: 100%;
    margin: 0;
  }

  .about-me a {
    font-size: 2vw;
  }
}

/**********  Skills Page   **********/

.skills #skills-title {
  margin: 22% 9% 0 0;
  color: #cfb2db;
  font-size: 6vw;
  font-family: Playfair Display, serif;
  text-align: left;
  display: inline-block;
  vertical-align: top;
}

.skills #skills-title p {
  margin: 0;
  padding: 0;
  line-height: 60px;
}

.skills #skills-brush {
  top: 270%;
  right: 0;
  width: 25%;
  position: absolute;
}

.skills-container {
  margin: 12% 10% 15% 2%;
  display: inline-block;
  width: 45%;
}

.skills-logo-container {
  align-content: center;
  display: inline-block;
  margin: 25px;
}

.skills-logo {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  box-shadow: 0 4px 6px 0 rgba(169, 164, 164, 0.455),
    0 6px 20px 0 rgba(202, 199, 199, 0.431);
}

.skills-logo img {
  width: 50%;
  height: 50%;
  margin: 27%;
}

.skill {
  margin: 0;
  display: inline-block;
  color: rgb(250, 234, 249);
  font-size: 13px;
}

/*      Skills - Mobile responsive     */

@media only screen and (max-width: 1200px) {
  .skills #skills-brush {
    visibility: hidden;
  }
  .skills #skills-title {
    font-size: 8vw;
    margin: 7% 0 14% 0;
  }

  .skills #skills-title p {
    margin: 0;
    padding: 0;
    line-height: 80%;
  }

  .skills-container {
    /* margin: 12% 10% 15% 2%; */
    /* display: inline-block; */
    margin: 7% 0 0 0;
    width: 80%;
  }

  .skills-logo-container {
    margin: 2%;
  }

  .skills-logo {
    width: 10vw;
    height: 10vw;
  }

  .skills-logo img {
    width: 50%;
    height: 50%;
    margin: 27%;
  }

  .skills-logo-container .skill {
    font-size: 2.3vw;
  }
}

/**********  Projects Page   **********/

.projects {
  background-color: #222626;
}

.projects .projects-title {
  margin: 33% 0 0 5%;
  color: #cfb2db;
  font-size: 6vw;
  font-family: Playfair Display, serif;
  text-align: left;
  display: inline-block;
  vertical-align: top;
}

.projects .projects-title p {
  margin: 0;
  padding: 0;
  line-height: 60px;
}

#projects-brush {
  width: 12%;
  position: absolute;
  left: 7%;
}

.projects .projects-container {
  margin: 10% 0 10% 3%;
  width: 60%;
  display: inline-block;
}

.project-box {
  display: inline-block;
  margin: 10px;
  width: 40%;
}

.project {
  width: 100%;
  position: relative;
  margin: auto;
}

.default-image img {
  width: 100%;
  border-radius: 30px;
}

.project .hover-info {
  position: absolute;
  background-color: rgba(180, 154, 175, 0.885);
  color: white;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 12px 28px;
  display: none;
  border-radius: 30px;
}

.project .hover-info a {
  display: inline-block;
  margin: 0px 20px;
  color: white;
}

.project:hover .hover-info {
  display: block;
}

.project-technologies {
  display: inline-block;
  margin: 2px;
  background-color: rgba(225, 201, 219, 0.849);
  border-radius: 9px;
  padding: 1px 7px;
  font-size: 12px;
  color: #495251;
}

.project-logo-container {
  margin-top: 3%;
}

.project-code-logo {
  width: 25px;
}

.project-demo-logo {
  width: 35px;
}

/*      Projects - Mobile responsive     */

@media only screen and (max-width: 1200px) {
  #projects-brush {
    visibility: hidden;
  }

  .projects .projects-title {
    margin: 10% 5% 0 5%;
    font-size: 8vw;
  }

  .projects .projects-title p {
    margin: 0;
    padding: 0;
    line-height: 80%;
  }

  .projects .projects-container {
    width: 80%;
    margin: 6% 0;
  }

  .project-box {
    margin: 10px;
    width: 90%;
  }

  .project {
    width: 100%;
  }

  .project .hover-info {
    padding: 6% 8%;
  }

  .project .hover-info a {
    display: inline-block;
    margin: 0;
    width: 20%;
  }

  .project .hover-info h5 {
    font-size: 5vw;
    margin-bottom: 4%;
  }

  .project .hover-info #project-info-p {
    margin-bottom: 0;
    font-size: 2.8vw;
  }

  .project-technologies {
    margin-top: 5%;
    font-size: 2.1vw;
  }

  .project-logo-container {
    margin-top: 3%;
  }

  .project-code-logo {
    width: 37%;
  }

  .project-demo-logo {
    width: 55%;
  }
}

/**********  Contact   **********/

.contact-me #contact-me-title {
  margin: 22% 5% 6% 5%;
  color: #cfb2db;
  font-size: 5.4vw;
  font-family: Playfair Display, serif;
  width: 30%;
  text-align: left;
  display: inline-block;
  vertical-align: top;
}

.contact-me #contact-me-title #title {
  margin: 0;
  padding: 0;
  line-height: 60px;
}

.contact-me #contact-me-message {
  font-size: 1.1vw;
  font-family: "Roboto";
  width: 70%;
  line-height: 15px;
  margin-top: 40px;
}

.contact-me #contact-me-pic {
  width: 40%;
  display: inline-block;
  margin: 15% 0 0 0;
}

/*      Contact - Mobile responsive     */

@media only screen and (max-width: 1200px) {
  .contact-me #contact-me-title {
    margin: 13% 0;
    color: #cfb2db;
    font-family: Playfair Display, serif;
    width: 37%;
    text-align: left;
    display: inline-block;
    vertical-align: top;
  }

  .contact-me #contact-me-title #title p {
    margin: 0;
    padding: 0;
    line-height: 80%;
    font-size: 6vw;
  }

  #contact-me-title #contact-me-message {
    font-size: 1.9vw;
    font-family: "Roboto";
    width: 70%;
    line-height: 90%;
    margin-top: 20%;
  }

  .contact-me #contact-me-pic {
    width: 47%;
    display: inline-block;
    margin: 14% 0 14% 0;
  }

  #contact-me-title #contact-button {
    font-size: 30%;
  }
}
